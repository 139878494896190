import { recordToLang } from '@platform/front-utils';
import { feature } from './feature';

const localesEn = recordToLang(
    {
        feature,
    },
    false,
);

export default localesEn;
