import { recordToLang } from '@platform/front-utils';
import { feature } from './feature';

const localesRu = recordToLang(
    {
        feature,
    },
    false,
);

export default localesRu;
